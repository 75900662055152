<ng-container *ngIf="$skillsLoadState | async as loadState">
  <ng-container [ngSwitch]="loadState">
    <ng-container *ngSwitchCase="KexLoadState.FAILURE">ERROR</ng-container>
    <ng-container *ngSwitchCase="KexLoadState.SUCCESS">
      <ng-container *ngIf="$skills | async as skills">
        <div *ngIf="skills.length < 1">
          <p>Füge deinem Profil jetzt die erste Fähigkeit hinzu</p>
          <kex-profile-skill />
        </div>
        <div *ngIf="skills.length > 0">
          <kex-profile-skill *ngFor="let skill of skills" [userSkill]="skill"/>
          <kex-profile-skill *ngIf="newSkill" (leaveNewSkillMode)="leaveAddSkillMode()"/>
        </div>
        <div class="kex-skills-button-container">
          <button mat-raised-button (click)="openRatingInfo()">Erklärung Level-System</button>
          <button id="kex-skills-button-add" mat-raised-button color="primary" (click)="onAddSkill()" [disabled]="newSkill">Fähigkeit hinzufügen</button>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <mat-spinner></mat-spinner>
    </ng-container>
  </ng-container>
</ng-container>
