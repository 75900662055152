<ng-container [ngSwitch]="$loadState| async">
  <ng-container *ngSwitchCase="KexLoadState.LOADING">
    <mat-spinner></mat-spinner>
  </ng-container>
  <ng-container *ngSwitchCase="KexLoadState.FAILURE">ERROR</ng-container>
  <ng-container *ngSwitchCase="KexLoadState.SUCCESS">
    <kex-profile-contact-option></kex-profile-contact-option>
    <kex-profile-contact-time class="my-4"></kex-profile-contact-time>
  </ng-container>
</ng-container>
