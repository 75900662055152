import { Component } from '@angular/core';

@Component({
  selector: 'kex-settings',
  templateUrl: './kex-settings.component.html',
  styleUrl: './kex-settings.component.scss'
})
export class KexSettingsComponent {

}
