<div class="kex-experience-container my-4">
  <div class="kex-experience-visible p-1" (click)="setVisibility()" *ngIf="visible">
    <mat-icon matTooltip="Erfahrung ist öffentlich">visibility</mat-icon>
  </div>
  <div class="kex-experience-visible-off p-1" (click)="setVisibility()" *ngIf="!visible">
    <mat-icon matTooltip="Erfahrung ist für andere nicht sichtbar">visibility_off</mat-icon>
  </div>
  <div class="kex-experience-content p-2">
    <div class="kex-experience-content-left">
      <h3 *ngIf="!editMode">{{ experience?.title }}</h3>
      <mat-form-field *ngIf="editMode" class="p-0 kex-form-field">
        <mat-label>Titel</mat-label>
        <input matInput [(ngModel)]="title">
      </mat-form-field>
      <p *ngIf="!editMode">{{ experience?.description }}</p>
      <mat-form-field *ngIf="editMode" class="kex-form-field-ex-disc">
        <mat-label>Beschreibung</mat-label>
        <textarea matInput [(ngModel)]="description" maxlength="255"></textarea>
      </mat-form-field>

     <!-- <p style="font-weight:bold">Fähigkeiten</p> -->
      <!-- Integration des Fähigkeiten Auto Complete Chip Lets -->
      <form [hidden]="!editMode">
        <mat-form-field class="kex-chip-list">
          <mat-chip-grid #chipGrid aria-label="Skill selection"  >
            <!-- Immer die Chips anzeigen -->
            @for (skill of linkedSkills; track skill) {
            <mat-chip-row (removed)="removeSkill(skill)">
              {{skill.title}}
              <!-- Zeige den Entfernen-Button nur, wenn editMode true ist -->
              <button *ngIf="editMode" matChipRemove [attr.aria-label]="'remove ' + skill">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
            }
          </mat-chip-grid>


          <input  #skillInput matInput placeholder="New Skill..."
                 [formControl]="skillCtrl" [matChipInputFor]="chipGrid"
                 [matAutocomplete]="auto" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 (matChipInputTokenEnd)="addNewSkill($event)">

          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectExistingSkill($event)">
            <!-- Autocomplete-Optionen -->
            <mat-option *ngFor="let skill of allProfileSkills | async" [value]="skill">
              {{skill.skill.title}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>

      <mat-chip-set  [hidden]="editMode">
        <mat-chip *ngFor="let skill of linkedSkills"
                  [ngClass]="isSkillVisible(skill) ? 'kex-chip-list-visible':'kex-chip-invisible' ">
          <span matListItemTitle>{{ skill.title }}</span>
        </mat-chip>
      </mat-chip-set>


      <!-- Ende der Integration des Fähigkeiten Auto Complete Chip Lets -->
    </div>
    <div class="kex-experience-button-container">
      <ng-container *ngIf="!editMode">
        <button class="mx-2" mat-mini-fab matTooltip="Löschen" color="secondary" (click)="deleteExperience()">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-mini-fab matTooltip="Bearbeiten" color="primary" (click)="goToEditMode()">
          <mat-icon>edit</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="editMode">
        <button class="mx-2" mat-mini-fab matTooltip="Abbrechen" color="secondary" (click)="leaveEditMode()">
          <mat-icon>close</mat-icon>
        </button>
        <button mat-mini-fab matTooltip="Speichern" color="primary" (click)="saveExperience()">
          <mat-icon>check</mat-icon>
        </button>
      </ng-container>
    </div>
  </div>
</div>
