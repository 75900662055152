<div class="d-flex align-items-center">
  <p class="fw-bold m-0">Gewünschte Kontaktzeiten</p>
  <button *ngIf="!editMode" color="primary" mat-icon-button matTooltip="Bearbeiten" (click)="goToEditMode()">
    <mat-icon>edit</mat-icon>
  </button>
</div>
<ng-container *ngIf="($contactTime | async) as contactTimeSlots">
  <div *ngIf="!editMode">
    <kex-contact-times [userContactTimes]="contactTimeSlots || []" [showHeadline]="false"></kex-contact-times>
  </div>
  <div *ngIf="editMode" >
    <div *ngFor="let timeSlot of contactTimeSlotsEdit; index as i">
      <div class="kex-contact-time-line my-4">
        <mat-form-field class="example-form-field">
          <mat-label>Tag</mat-label>
          <mat-select [(ngModel)]="timeSlot.day">
            <mat-option value="MONDAY">Montag</mat-option>
            <mat-option value="TUESDAY">Dienstag</mat-option>
            <mat-option value="WEDNESDAY">Mittwoch</mat-option>
            <mat-option value="THURSDAY">Donnerstag</mat-option>
            <mat-option value="FRIDAY">Freitag</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Von</mat-label>
          <input matInput
                 [format]=24
                 [ngxMatTimepicker]="pickerFrom"
                 [(ngModel)]="timeSlot.fromTimeDisplayed">
          <mat-icon matSuffix
                    (click)="pickerFrom.open()">
            watch_later
          </mat-icon>
        </mat-form-field>
        <ngx-mat-timepicker color="primary" #pickerFrom></ngx-mat-timepicker>
        <mat-form-field>
          <mat-label>Von</mat-label>
          <input matInput
                 [format]=24
                 [ngxMatTimepicker]="pickerTo"
                 [(ngModel)]="timeSlot.toTimeDisplayed">
          <mat-icon matSuffix
                    (click)="pickerTo.open()">
            watch_later
          </mat-icon>
        </mat-form-field>
        <ngx-mat-timepicker color="primary" #pickerTo></ngx-mat-timepicker>
        <kex-button class="mx-2" tooltip="Löschen" icon="delete" (click)="deleteTimeSlot(i)"></kex-button>
      </div>

    </div>
    <div class="kex-profile-contact-time-button-footer">
      <button mat-raised-button (click)="addTimeSlot()">Zeile hinzufügen</button>
      <div>
        <button mat-raised-button (click)="cancel()" id="kex-contact-time-cancel-button">Abbrechen</button>
        <button mat-raised-button color="primary" (click)="saveContactTime()">Speichern</button>
      </div>
    </div>

  </div>
</ng-container>
