<mat-toolbar color="primary" class="justify-content-between">
  <span class="clickable-span" routerLink="/home">KEX - Knowledge & Experience</span>

  <div class="kex-toolbar-right">
    <div class="kex-toolbar-user" *ngIf="currentUser$ | async as user">
      <p >{{user.firstname}} {{user.lastname}}</p>
     <!-- <p >Master Software Engineering (WS 2023)</p> -->
    </div>
    <button mat-icon-button color="secondary" [matMenuTriggerFor]="menu">
      <mat-icon>account_box</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item routerLink="/profile">
        <span>Profil bearbeiten</span>
      </button>
      <button mat-menu-item (click)="onLogout()">
        <span>Ausloggen</span>
      </button>
      <div class="kex-user-info-mobile" *ngIf="currentUser$ | async as user">
        <p>{{user.firstname}} {{user.lastname}}</p>
        <!-- <p>Master Software Engineering</p>
        <p>Wintersemester 2023</p> -->
      </div>
    </mat-menu>
    <mat-divider class="divider-container" vertical="true"></mat-divider>
    <dark-mode-toggle class="theme-toggle"></dark-mode-toggle>
  </div>
</mat-toolbar>
