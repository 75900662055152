<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>{{title}}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    @switch (loadState) {
      @case (KexLoadState.LOADING) {
        <mat-spinner></mat-spinner>
      }
      @case (KexLoadState.FAILURE) {
        <p>Error</p>
      }
      @default {
        <ng-content></ng-content>
      }
    }
  </mat-card-content>
  <mat-card-actions *ngIf="actionLabel" align="end">
    <button mat-raised-button color="primary" (click)="clickButton()">{{actionLabel}}</button>
  </mat-card-actions>
</mat-card>
