<div class="kex-profile-container">
  <div class="kex-profile-headline">
    <h1>Profil bearbeiten</h1>
    <kex-button [type]="KexButtonType.RAISE_BUTTON" (click)="deleteProfile()"
                label="Profil löschen" [loadingState]="$deleteLoadState | async" color="secondary"></kex-button>
  </div>

  <mat-tab-group  mat-align-tabs="start"
                  [selectedIndex]="selectedTab"
                  (selectedIndexChange)="changeTab($event)"
  >
    <mat-tab label="Fähigkeiten">
      <kex-profile-skills ></kex-profile-skills>
    </mat-tab>
    <mat-tab label="Erfahrungen">
      <kex-profile-experiences></kex-profile-experiences>
    </mat-tab>
    <mat-tab label="Kontaktdaten">
      <kex-profile-contact-data ></kex-profile-contact-data>
    </mat-tab>
  </mat-tab-group>
</div>

